import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ModalBody, Box, Text, Truncate } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";
import { ProductAssetsIcon } from "@twilio-paste/icons/cjs/ProductAssetsIcon";

export type TemplateMessageMedia = {
  filename: string;
  filesize: number;
  path: string;
};

export type TemplateMessageEntity = {
  body: string;
  date: string;
  author: string;
  media?: TemplateMessageMedia;
};

interface TemplateMessageProps {
  message: TemplateMessageEntity;
}

const statusStyle = {
  display: "inline-block",
  verticalAlign: "middle",
  marginLeft: "4px",
};

const statusIconStyle = {
  marginLeft: "10px",
};

export const TemplateMessage: React.FC<TemplateMessageProps> = (
  props: TemplateMessageProps
) => {
  const theme = useTheme();

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          paddingBottom: 10,
        }}
      >
        <Box
          style={{
            backgroundColor: theme.backgroundColors.colorBackgroundPrimary,
            color: theme.backgroundColors.colorBackgroundBody,
            fontFamily: theme.fonts.fontFamilyText,
            fontSize: theme.fontSizes.fontSize30,
            fontWeight: theme.fontWeights.fontWeightNormal,
            lineHeight: theme.lineHeights.lineHeight30,
            paddingTop: theme.space.space40,
            paddingBottom: theme.space.space40,
            paddingLeft: theme.space.space30,
            paddingRight: theme.space.space30,
            borderRadius: theme.space.space30,
          }}
        >
          <Box
            style={{
              paddingTop: theme.space.space30,
              whiteSpace: "pre-wrap",
            }}
          >
            {props.message.media ? (
              <>
                <div>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "12px 16px",
                      marginTop: "6px",
                      border: "1px solid #CACDD8",
                      boxSizing: "border-box",
                      minWidth: "150px",
                      backgroundColor: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      style={{
                        marginRight: "16px",
                        alignItems: "start",
                      }}
                    >
                      <ProductAssetsIcon
                        decorative={false}
                        title="Open File"
                        size="sizeIcon60"
                        color="colorTextLink"
                        style={{
                          fontWeight: "bold",
                        }}
                      />
                    </Box>
                    <Box
                      style={{
                        maxWidth: "calc(100% - 42px)",
                      }}
                    >
                      <Text as="p" fontWeight="fontWeightMedium">
                        <Truncate title="{props.message.media.filename}">
                          {props.message.media.filename}
                        </Truncate>
                      </Text>
                      <Text as="p" color="colorTextInverseWeaker">
                        {Math.round(
                          (props.message.media.filesize / Math.pow(2, 20)) * 100
                        ) / 100}
                        MB
                      </Text>
                    </Box>
                  </Box>
                </div>
              </>
            ) : null}
            {props.message.body}
          </Box>
          <Box
            style={{
              display: "flex",
              flexGrow: 10,
              paddingTop: theme.space.space30,
              justifyContent: "space-between",
            }}
          >
            <Box fontSize={theme.fontSizes.fontSize20}>
              {props.message.date}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TemplateMessage;
