import { CSSProperties } from "react";

export default {
  appContainer: (alertsExist: boolean): CSSProperties => ({
    display: "flex",
    flexDirection: "row",
    height: alertsExist ? "calc(100% - 122px)" : "calc(100% - 72px)",
    width: "100%",
    pointerEvents: alertsExist ? "none" : "auto",
  }),
  appLogo: {
    display: "flex",
    margin: "auto 0",
  },
  flex: {
    display: "flex",
  },
  appWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  } as CSSProperties,
  convosWrapper: (isDesktop: boolean) =>
    ({
      height: isDesktop ? "100%" : "calc(100% - 72px)",
      width: 320,
      top: isDesktop ? "auto" : "72px",
      position: isDesktop ? "relative" : "fixed",
      backgroundColor: "#F4F4F6",
      zIndex: 70,
    } as CSSProperties),
  // convosWrapper: {
  //   height: "100%",
  //   width: 320,
  //   position: "relative",
  //   backgroundColor: "#F4F4F6",
  // } as CSSProperties,
  // messagesWrapper: {
  //   flex: 1,
  // } as CSSProperties,
  messagesWrapper: (isDesktop: boolean) =>
    ({
      flex: 1,
      padding: isDesktop ? "0" : "0 0 0 70px",
    } as CSSProperties),
  appHeader: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#3297fc",
    padding: "20px 25px",
  },
  appLogoTitle: {
    fontSize: "16px",
    fontWeight: 500,
    textAlign: "center",
    color: "#FFFFFF",
    lineHeight: "32px",
    paddingLeft: "20px",
  } as CSSProperties,
  userTile: {
    display: "flex",
    lineHeight: "32px",
    color: "#fff",
  },
  userName: {
    padding: "0 10px",
    maxWidth: "215px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  } as CSSProperties,
  paginationSpinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
  } as CSSProperties,
  app: {
    flex: 1,
    height: "100%",
    width: "100%",
    position: "absolute",
  } as CSSProperties,
  buttonWrapper: {
    flexDirection: "row",
    paddingRight: "16px",
  } as CSSProperties,
  convosWrapperBox: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  } as CSSProperties,
  convoList: {
    overflow: "scroll",
    position: "absolute",
    top: "65px",
    bottom: "60px",
  } as CSSProperties,
  collapseButtonBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    paddingTop: "12px",
    paddingBottom: "20px",
    paddingRight: "16px",
    paddingLeft: "16px",
    position: "absolute",
    bottom: 0,
    borderTop: "1px solid #CACDD8",
  } as CSSProperties,
  newConvoButton: {
    padding: "14px 16px",
    width: "100%",
    borderBottom: "1px solid #CACDD8",
  } as CSSProperties,
  loginContainer: {
    height: "100%",
    backgroundColor: "#3297fc",
  } as CSSProperties,
  logo: {
    margin: "0 0 10px",
  } as CSSProperties,
  logoApp: (isDesktop: boolean) =>
    ({
      display: isDesktop ? "block" : "none",
    } as CSSProperties),
  loginTitle: {
    paddingTop: "2px",
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 500,
  } as CSSProperties,
  subTitle: {
    color: "#AEB2C1",
    fontFamily: "Inter",
    fontSize: "14px",
    lineHeight: "20px",
    paddingBottom: "40px",
  } as CSSProperties,
  loginContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 3,
  } as CSSProperties,
  loginForm: {
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "4px 4px 15px #666",
    margin: "0 10px",
  } as CSSProperties,
  userInput: {
    paddingTop: "24px",
    maxWidth: "320px",
    marginLeft: "24px",
    marginRight: "24px",
  } as CSSProperties,
  passwordInput: {
    paddingTop: "16px",
    maxWidth: "320px",
    marginLeft: "24px",
    marginRight: "24px",
  } as CSSProperties,
  loginButton: {
    fontFamily: "Inter",
    paddingTop: "32px",
    paddingBottom: "24px",
    marginLeft: "24px",
    marginRight: "24px",
  } as CSSProperties,
  loginBackground: {
    position: "absolute",
    alignSelf: "flex-end",
    top: "50%",
    height: "50%",
    width: "100%",
    backgroundColor: "#3297fc",
    overflow: "hidden",
  } as CSSProperties,
  modalInputLabel: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
  } as CSSProperties,
  settingsWrapper: {
    zIndex: 1,
    paddingTop: "22px",
  } as CSSProperties,
  optionWrapper: {
    width: "232px",
  } as CSSProperties,
  // collapsedList: {
  //   width: "70px",
  // } as CSSProperties,
  collapsedList: (isDesktop: boolean) =>
    ({
      // height: isDesktop ? "100%" : "calc(100% - 72px)",
      width: "70px",
      top: isDesktop ? "auto" : "72px",
      // position: isDesktop ? "relative" : "fixed",
      // backgroundColor: "#F4F4F6",
      // zIndex: 10000,
    } as CSSProperties),
};
