import React, { useState } from "react";
import { Client } from "@twilio/conversations";
import { Box } from "@twilio-paste/core";
import { MenuButton, Menu, MenuItem, useMenuState } from "@twilio-paste/menu";
import {
  MediaObject,
  MediaFigure,
  MediaBody,
} from "@twilio-paste/media-object";
import { MoreIcon } from "@twilio-paste/icons/esm/MoreIcon";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";

import TemplateModal from "./TemplateModal";
import { NotificationsType } from "../../store/reducers/notificationsReducer";
import styles from "../../styles";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { DeleteIcon } from "@twilio-paste/icons/cjs/DeleteIcon";

interface SettingsMenuProps {
  leaveConvo: () => void;
  updateConvo: (val: string) => Promise<void>;
  conversation: ReduxConversation;
  client: Client;
  onParticipantListOpen: () => void;
  addNotifications: (messages: NotificationsType) => void;
}

function setNativeValue(element: HTMLInputElement, value: string) {
  const elementPropertyDescriptor = Object.getOwnPropertyDescriptor(
    element,
    "value"
  );
  const prototype = Object.getPrototypeOf(element);
  if (elementPropertyDescriptor && prototype) {
    const valueSetter = elementPropertyDescriptor.set;
    const prototypePropertyDescriptor = Object.getOwnPropertyDescriptor(
      prototype,
      "value"
    );
    if (prototypePropertyDescriptor) {
      const prototypeValueSetter = prototypePropertyDescriptor.set;

      if (
        valueSetter &&
        prototypeValueSetter &&
        valueSetter !== prototypeValueSetter
      ) {
        prototypeValueSetter.call(element, value);
      } else if (valueSetter) {
        valueSetter.call(element, value);
      }
    }
  }
}

const TemplatesMenu: React.FC<SettingsMenuProps> = (
  props: SettingsMenuProps
) => {
  const menu = useMenuState();
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState("");

  const onSave = async (messages: any) => {
    setIsTemplateModalOpen("");

    if (messages) {
      const messageInput = document.getElementById(
        "message-input"
      ) as HTMLInputElement;
      if (messageInput) {
        let messageText = "";
        for (let i = 0; i < messages.length; i++) {
          const message = messages[i];
          messageText += message.body;
        }
        setNativeValue(messageInput, messageText);
        messageInput.dispatchEvent(new Event("input", { bubbles: true }));
      }
    }
  };

  return (
    <Box style={styles.settingsWrapper}>
      <MenuButton {...menu} variant="link" size="reset">
        <MoreIcon decorative={false} title="Templates" />
      </MenuButton>
      <Menu {...menu} aria-label="Templates">
        <Box style={styles.optionWrapper}>
          <MenuItem {...menu}>
            <MediaObject verticalAlign="center">
              <MediaBody
                onClick={() => setIsTemplateModalOpen("new_onboarding")}
              >
                Onboarding
              </MediaBody>
              <TemplateModal
                title={props.conversation.attributes.first_name}
                type="new_onboarding"
                isModalOpen={isTemplateModalOpen === "new_onboarding"}
                onCancel={() => {
                  setIsTemplateModalOpen("");
                }}
                onSave={onSave}
              />
            </MediaObject>
          </MenuItem>
          <MenuItem {...menu} onClick={props.onParticipantListOpen}>
            <MediaObject verticalAlign="center">
              <MediaFigure spacing="space20">
                <UserIcon
                  decorative={false}
                  title="information"
                  color="colorTextIcon"
                />
              </MediaFigure>
              <MediaBody>Manage Participants</MediaBody>
            </MediaObject>
          </MenuItem>
          <MenuItem
            {...menu}
            onClick={async () => {
              const conversation = await props.client.getConversationBySid(
                props.conversation.sid
              );
              await conversation.delete();
            }}
          >
            <MediaObject verticalAlign="center">
              <MediaFigure spacing="space20">
                <DeleteIcon
                  decorative={false}
                  title="information"
                  color="colorTextIcon"
                />
              </MediaFigure>
              <MediaBody>Remove conversation</MediaBody>
            </MediaObject>
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  );
};

export default TemplatesMenu;
