import React, { useState, useEffect, useMemo, useRef } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import {
  Message,
  Conversation,
  Participant,
  Client,
} from "@twilio/conversations";
import { Box, Spinner } from "@twilio-paste/core";

import { actionCreators, AppState } from "../store";
import ConversationContainer from "./conversations/ConversationContainer";
import ConversationsContainer from "./conversations/ConversationsContainer";
import {
  AddMessagesType,
  SetParticipantsType,
  SetUnreadMessagesType,
  UserInfo,
} from "../types";
import {
  getConversationParticipants,
  getToken,
  postOnboardingDate,
} from "../api";
import useAppAlert from "../hooks/useAppAlerts";
import Notifications from "./Notifications";
import stylesheet from "../styles";
import {
  handlePromiseRejection,
  isMessageIncludeOnboardingResult,
} from "../helpers";
import AppHeader from "./AppHeader";
import useMediaQuery from "../hooks/useMediaQuery";

import {
  initFcmServiceWorker,
  subscribeFcmNotifications,
  showNotification,
} from "../firebase-support";

async function loadUnreadMessagesCount(
  convo: Conversation,
  updateUnreadMessages: SetUnreadMessagesType
) {
  let count = 0;

  try {
    count =
      (await convo.getUnreadMessagesCount()) ??
      (await convo.getMessagesCount());
  } catch (e) {
    console.error("getUnreadMessagesCount threw an error", e);
  }

  updateUnreadMessages(convo.sid, count);
}

async function handleParticipantsUpdate(
  participant: Participant,
  updateParticipants: SetParticipantsType
) {
  const result = await getConversationParticipants(participant.conversation);
  updateParticipants(result, participant.conversation.sid);
}

async function getSubscribedConversations(
  client: Client
): Promise<Conversation[]> {
  let subscribedConversations = await client.getSubscribedConversations();
  let conversations = subscribedConversations.items;

  while (subscribedConversations.hasNextPage) {
    subscribedConversations = await subscribedConversations.nextPage();
    conversations = [...conversations, ...subscribedConversations.items];
  }

  return conversations;
}

const AppContainer: React.FC = () => {
  /* eslint-disable */
  const isDesktop = useMediaQuery("(min-width: 500px)");
  const [client, setClient] = useState<Client>();
  const token = useSelector((state: AppState) => state.token);
  const loading = useSelector((state: AppState) => state.loadingStatus);
  const conversations = useSelector((state: AppState) => state.convos);
  const sid = useSelector((state: AppState) => state.sid);
  const sidRef = useRef("");
  const [alertsExist, AlertsView] = useAppAlert();
  sidRef.current = sid;

  const dht = localStorage.getItem("dht");
  const username = localStorage.getItem("username");

  const dispatch = useDispatch();
  const {
    addMessages,
    updateLoadingState,
    updateParticipants,
    updateUnreadMessages,
    startTyping,
    endTyping,
    addConversation,
    login,
    removeMessages,
    updateMessages,
    removeConversation,
    updateCurrentConversation,
    addNotifications,
    logout,
    clearAttachments,
  } = bindActionCreators(actionCreators, dispatch);

  const updateTypingIndicator = (
    participant: Participant,
    sid: string,
    callback: (sid: string, user: string) => void
  ) => {
    const {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      attributes: { friendlyName },
      identity,
    } = participant;
    if (identity === localStorage.getItem("username")) {
      return;
    }
    callback(sid, identity || friendlyName || "");
  };
  useEffect(() => {
    const client = new Client(token);
    setClient(client);

    const fcmInit = async () => {
      await initFcmServiceWorker();
      await subscribeFcmNotifications(client);
    };

    fcmInit().catch(() => {
      console.error(
        "FCM initialization failed: no push notifications will be available"
      );
    });
    updateLoadingState(true);
      (async () => {
        client.on("conversationJoined",  (conversation) => {
          addConversation(conversation);

          conversation.on("typingStarted", async (participant) => {
          await handlePromiseRejection(
                () =>
                    updateTypingIndicator(participant, conversation.sid, startTyping),
                addNotifications
            );
          });

          conversation.on("typingEnded", async(participant) => {
            await handlePromiseRejection(
                () => updateTypingIndicator(participant, conversation.sid, endTyping),
                addNotifications
            );
          });

          handlePromiseRejection(async () => {
            if (conversation.status === "joined") {
              const result = await getConversationParticipants(conversation);
              updateParticipants(result, conversation.sid);

              const messages = await conversation.getMessages();
              addMessages(conversation.sid, messages.items);
              await loadUnreadMessagesCount(conversation, updateUnreadMessages);
            }
          }, addNotifications);
        });

        client.on("conversationRemoved", async (conversation: Conversation) => {
          updateCurrentConversation("");
          await handlePromiseRejection(() => {
            removeConversation(conversation.sid);
            updateParticipants([], conversation.sid);
          }, addNotifications);
        });
        client.on("messageAdded", async (message: Message) => {
          await addMessage(message, addMessages, updateUnreadMessages);
          if (message.author === localStorage.getItem("username")) {
            clearAttachments(message.conversation.sid, "-1");
          }

        });
        client.on("participantLeft", async (participant) => {
         await handlePromiseRejection(
              () => handleParticipantsUpdate(participant, updateParticipants),
              addNotifications
          );
        });
        client.on("participantUpdated", async (event) => {
         await handlePromiseRejection(
              () => handleParticipantsUpdate(event.participant, updateParticipants),
              addNotifications
          );
        });
        client.on("participantJoined", async (participant) => {
         await handlePromiseRejection(
              () => handleParticipantsUpdate(participant, updateParticipants),
              addNotifications
          );
        });
        client.on("conversationUpdated", async ({ conversation }) => {
         await handlePromiseRejection(() => {}, addNotifications);
        });

        client.on("messageUpdated", async ({ message, updateReasons }) => {
          if (updateReasons.includes("deliveryReceipt")) {
           await handlePromiseRejection(
                () => updateMessages(message.conversation.sid, [message]),
                addNotifications
            );
          }
        });

        client.on("messageRemoved", async (message) => {
         await handlePromiseRejection(
              () => removeMessages(message.conversation.sid, [message]),
              addNotifications
          );
        });

        client.on("pushNotification",  (event) => {
          // @ts-ignore
          if (event.type != "twilio.conversations.new_message") {
            return;
          }

          if (Notification.permission === "granted") {
            showNotification(event);
          } else {
            console.log("Push notification is skipped", Notification.permission);
          }
        });

        client.on("tokenAboutToExpire", async () => {
          if (dht) {
          await  getToken(dht).then((token) => {
              login(token);
            });
          }
        });
        await getSubscribedConversations(client);
      })().finally(() => {
        updateLoadingState(false);
      });



    return () => {
      client?.removeAllListeners();
    };
  }, []);

  async function addMessage(
    message: Message,
    addMessages: AddMessagesType,
    updateUnreadMessages: SetUnreadMessagesType
  ) {
    //transform the message and add it to redux
    await handlePromiseRejection(async () => {
      if (sidRef.current === message.conversation.sid) {
       await message.conversation.updateLastReadMessageIndex(message.index);
      }
      try {
        if(isMessageIncludeOnboardingResult(message)) {
          const userAttributes = message.conversation.attributes  as UserInfo
          await postOnboardingDate(userAttributes?.id)
        }
      }catch (error){
        console.log(error)
      }
      addMessages(message.conversation.sid, [message]);
      await loadUnreadMessagesCount(message.conversation, updateUnreadMessages);
    }, addNotifications);
  }

  const openedConversation = useMemo(
    () => conversations.find((convo) => convo.sid === sid),
    [sid, conversations]
  );

  if (loading) {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            height="100%"
            width="100%"
        >
          <Spinner size="sizeIcon110" decorative={false} title="Loading" />
        </Box>
    );
  }

  return (
    <Box style={stylesheet.appWrapper}>
      <AlertsView />
      <Notifications />
      <Box>
        <AppHeader
          user={username ?? ""}
          onSignOut={async () => {
            logout();

            // unregister service workers
            const registrations =
              await navigator.serviceWorker.getRegistrations();
            for (let registration of registrations) {
              await registration.unregister();
            }
          }}
        />
      </Box>
      <Box style={stylesheet.appContainer(alertsExist)}>
        <ConversationsContainer client={client} />
        <Box style={stylesheet.messagesWrapper(isDesktop)}>
          <ConversationContainer
            conversation={openedConversation}
            client={client}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AppContainer;
