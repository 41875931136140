import {
  Conversation,
  Message,
  Participant,
  Media,
  Client,
  Paginator,
  JSONValue,
} from "@twilio/conversations";

import {
  MessageStatus,
  ReduxMessage,
} from "./store/reducers/messageListReducer";
import {
  CONVERSATION_MESSAGES,
  CONVERSATION_PAGE_SIZE,
  PARTICIPANT_MESSAGES,
  UNEXPECTED_ERROR_MESSAGE,
} from "./constants";
import { NotificationsType } from "./store/reducers/notificationsReducer";
import { successNotification, unexpectedErrorNotification } from "./helpers";
import { getSdkMessageObject } from "./conversations-objects";
import { ReduxParticipant } from "./store/reducers/participantsReducer";
import { UserInfo } from "./types";

type ParticipantResponse = ReturnType<typeof Conversation.prototype.add>;

export async function addConversation(
  friendlyName: string,
  uniqueName: string,
  attributes: JSONValue,
  updateParticipants: (participants: Participant[], sid: string) => void,
  client?: Client,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<Conversation> {
  if (friendlyName.length > 0 && client !== undefined) {
    try {
      const conversation = await client.createConversation({
        friendlyName: friendlyName,
        uniqueName: uniqueName,
        attributes: attributes,
      });
      await conversation.join();

      const participants = await getConversationParticipants(conversation);
      updateParticipants(participants, conversation.sid);

      successNotification({
        message: CONVERSATION_MESSAGES.CREATED,
        addNotifications,
      });

      return conversation;
    } catch (e) {
      unexpectedErrorNotification(addNotifications);

      return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
  }
  return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
}

export async function addParticipant(
  name: string,
  proxyName: string,
  chatParticipant: boolean,
  convo?: Conversation,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<ParticipantResponse> {
  if (convo === undefined) {
    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
  }

  if (chatParticipant && name.length > 0) {
    try {
      const result = await convo.add(name);
      successNotification({
        message: PARTICIPANT_MESSAGES.ADDED,
        addNotifications,
      });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  if (!chatParticipant && name.length > 0 && proxyName.length > 0) {
    try {
      const result = await convo.addNonChatParticipant(proxyName, name, {
        friendlyName: name,
      });
      successNotification({
        message: PARTICIPANT_MESSAGES.ADDED,
        addNotifications,
      });

      return result;
    } catch (e) {
      unexpectedErrorNotification(addNotifications);

      return Promise.reject(e);
    }
  }
  return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
}

export async function getDht(
  username: string,
  password: string
): Promise<string> {
  const requestAddress = process.env.REACT_APP_DH_API_DOMAIN as string;

  if (!requestAddress) {
    return Promise.reject(
      "REACT_APP_DH_API_DOMAIN is not configured, cannot login"
    );
  }

  try {
    const response = await fetch(requestAddress + "/auth/sign-in", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: username,
        password: password,
        userType: "SYS_USER",
      }),
    });
    const res_data = await response.json();

    if (res_data.accessToken) {
      return res_data.accessToken;
    }

    return Promise.reject("Authentication error.");
  } catch (error) {
    process.stderr?.write(`ERROR received from ${requestAddress}: ${error}\n`);
    return Promise.reject(`ERROR received from ${requestAddress}: ${error}\n`);
  }
}

export async function getToken(dht: string): Promise<string> {
  const requestAddress = process.env.REACT_APP_DH_API_DOMAIN as string;

  if (!requestAddress) {
    return Promise.reject(
      "REACT_APP_DH_API_DOMAIN is not configured, cannot login"
    );
  }

  try {
    const response = await fetch(
      requestAddress + "/twilio/conversation-token",
      {
        method: "GET",
        headers: {
          "X-ACCESS-TOKEN": dht,
        },
      }
    );
    const res_data = await response.json();

    if (res_data.tcat) {
      return res_data.tcat;
    }

    return Promise.reject("Authentication error.");
  } catch (error) {
    process.stderr?.write(`ERROR received from ${requestAddress}: ${error}\n`);
    return Promise.reject(`ERROR received from ${requestAddress}: ${error}\n`);
  }
}

export async function postOnboardingDate(userId: string): Promise<UserInfo> {
  const requestAddress = process.env.REACT_APP_DH_API_DOMAIN as string;
  const accessToken = localStorage.getItem("dht") as string;

  if (!accessToken) {
    throw new Error("Incorrect token");
  }
  if (!userId) {
    throw new Error("Incorrect user id");
  }
  if (!requestAddress) {
    throw new Error("REACT_APP_DH_API_DOMAIN is not configured, cannot login");
  }

  try {
    const response = await fetch(requestAddress + `/user/${userId}/vcard`, {
      body: JSON.stringify({
        savedAt: new Date().toISOString().split("T")[0],
      }),
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-ACCESS-TOKEN": accessToken,
      },
    });
    return (await response.json()) as UserInfo;
  } catch (error) {
    throw new Error(`ERROR received from ${requestAddress}: ${error}\n`);
  }
}

export async function getTemplates(
  dht: string,
  template: string
): Promise<any> {
  const requestAddress = process.env.REACT_APP_DH_API_DOMAIN as string;

  if (!requestAddress) {
    return Promise.reject(
      "REACT_APP_DH_API_DOMAIN is not configured, cannot login"
    );
  }

  try {
    // disabled during testing
    // const response = await fetch(
    //   requestAddress + "/twilio/conversation-templates/" + template,
    //   {
    //     method: "GET",
    //     headers: {
    //       "X-ACCESS-TOKEN": dht,
    //     },
    //   }
    // );
    // const res_data = await response.json();

    const templates = {
      onboarding: [
        {
          date: "Nov 10",
          author: "Dailyhuman",
          body: `This is Kim from Dailyhuman 🚀 I'm here to help you with your Dailyhuman practice. To get started, please save two contacts to your phone. By doing so, you'll be able to recognize the number when you receive a text from Dailyhuman.

One number is for SUPPORT (https://bit.ly/3lyLP8R), and the other is for the weekly PRACTICE (https://bit.ly/3KtX4rz).

Please click on the links and save them to your phone. Once you've done that, please reply with "SAVED" so we know you've saved both contacts :)`,
        },
      ],
      new_onboarding: [
        {
          date: "Nov 10",
          author: "Dailyhuman",
          body: `This is Kim from Dailyhuman 🚀 I'm here to help you with your Dailyhuman practice. To get started, please save two contacts to your phone. By doing so, you'll be able to recognize the number when you receive a text from Dailyhuman.

One number is for SUPPORT (https://dh.live/3lyLP8R), and the other is for the weekly PRACTICE (https://dh.live/3KtX4rz).

Please click on the links and save them to your phone. Once you've done that, please reply with "SAVED" so we know you've saved both contacts :)`,
        },
      ],
    };

    const res_data = {
      templates: templates,
    };

    if (res_data.templates) {
      return res_data.templates;
    }

    return Promise.reject("Getting templates error.");
  } catch (error) {
    // process.stderr?.write(`ERROR received from ${requestAddress}: ${error}\n`);
    return Promise.reject(`ERROR received from ${requestAddress}: ${error}\n`);
  }
}

// export async function searchAllUsers(dht: string): Promise<DHUser[]> {
export async function searchAllUsers(
  dht: string,
  keyword: string
): Promise<any> {
  const requestAddress = process.env.REACT_APP_DH_API_DOMAIN as string;

  try {
    const response = await fetch(
      requestAddress +
        "/user?hasPhone=true&type=CUSTOMER&status=ACTIVE&keyword=" +
        keyword,
      {
        method: "GET",
        headers: {
          "X-ACCESS-TOKEN": dht,
        },
      }
    );
    const res_data = await response.json();

    if (res_data.data) {
      for (let i = 0; i < res_data.data.length; i++) {
        res_data.data[i].contact_phone = "+" + res_data.data[i].phone;
        res_data.data[i].first_name = res_data.data[i].firstName;
        res_data.data[i].last_name = res_data.data[i].lastName;
        res_data.data[i].username = res_data.data[i].nickname;
      }
      return res_data.data;
    }

    return Promise.reject("Authentication error.");
  } catch (error) {
    process.stderr?.write(`ERROR received from ${requestAddress}: ${error}\n`);
    return Promise.reject(`ERROR received from ${requestAddress}: ${error}\n`);
  }
}

// export async function getUserByPhone(dht: string): Promise<DHUser> {
export async function getUserByPhone(dht: string, phone: string): Promise<any> {
  const requestAddress = process.env.REACT_APP_DH_API_DOMAIN as string;

  phone = phone.replace("+", "");

  try {
    const response = await fetch(
      requestAddress +
        "/user?hasPhone=true&type=CUSTOMER&status=ACTIVE&keyword=" +
        phone,
      {
        method: "GET",
        headers: {
          "X-ACCESS-TOKEN": dht,
        },
      }
    );
    const res_data = await response.json();

    if (res_data.data.length) {
      res_data.data[0].contact_phone = "+" + res_data.data[0].phone;
      res_data.data[0].first_name = res_data.data[0].firstName;
      res_data.data[0].last_name = res_data.data[0].lastName;
      res_data.data[0].username = res_data.data[0].nickname;
      return res_data.data[0];
    }

    return Promise.reject("Authentication error.");
  } catch (error) {
    process.stderr?.write(`ERROR received from ${requestAddress}: ${error}\n`);
    return Promise.reject(`ERROR received from ${requestAddress}: ${error}\n`);
  }
}

export async function getConversationByUniqueName(
  uniqueName: string,
  client: Client
): Promise<Conversation> {
  return await client.getConversationByUniqueName(uniqueName);
}

export async function getMessageStatus(
  message: ReduxMessage,
  channelParticipants: ReduxParticipant[]
): Promise<{
  [MessageStatus.Delivered]?: number;
  [MessageStatus.Read]?: number;
  [MessageStatus.Failed]?: number;
  [MessageStatus.Sending]?: number;
}> {
  // FIXME should be: return statuses[message.sid];
  // after this modification:
  // message.on("updated", ({ message, updateReasons }) => {
  // if reason includes "deliveryReceipt" {
  //   // paginate detailed receipts
  //   const receipts = await message.getDetailedDeliveryReceipts(); // paginated backend query every time
  // }
  // });

  const statuses = {
    [MessageStatus.Delivered]: 0,
    [MessageStatus.Read]: 0,
    [MessageStatus.Failed]: 0,
    [MessageStatus.Sending]: 0,
  };

  if (message.index === -1) {
    return Promise.resolve({
      ...statuses,
      [MessageStatus.Sending]: 1,
    });
  }

  channelParticipants.forEach((participant) => {
    if (
      participant.identity == localStorage.getItem("username") ||
      participant.type !== "chat"
    ) {
      return;
    }

    if (
      participant.lastReadMessageIndex &&
      participant.lastReadMessageIndex >= message.index
    ) {
      statuses[MessageStatus.Read] += 1;
    } else if (participant.lastReadMessageIndex !== -1) {
      // statuses[MessageStatus.Delivered] += 1; FIXME don't need Delivered status for chat particpants?
    }
  });

  if (message.aggregatedDeliveryReceipt) {
    const sdkMessage = getSdkMessageObject(message);
    const receipts = await sdkMessage.getDetailedDeliveryReceipts(); // paginated backend query every time

    receipts.forEach((receipt) => {
      if (receipt.status === "read") {
        statuses[MessageStatus.Read] += 1;
      }

      if (receipt.status === "delivered") {
        statuses[MessageStatus.Delivered] += 1;
      }

      if (receipt.status === "failed" || receipt.status === "undelivered") {
        statuses[MessageStatus.Failed] += 1;
      }

      if (receipt.status === "sent" || receipt.status === "queued") {
        statuses[MessageStatus.Sending] += 1;
      }
    });
  }

  return statuses;
}

export const getConversationParticipants = async (
  conversation: Conversation
): Promise<Participant[]> => await conversation.getParticipants();

export const removeParticipant = async (
  conversation: Conversation,
  participant: string,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<void> => {
  try {
    await conversation.removeParticipant(participant);
    successNotification({
      message: PARTICIPANT_MESSAGES.REMOVED,
      addNotifications,
    });
  } catch {
    unexpectedErrorNotification(addNotifications);
    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getBlobFile = async (
  media: Media,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<Blob> => {
  try {
    const url = await getFileUrl(media);
    const response = await fetch(url);
    return response.blob();
  } catch (e) {
    unexpectedErrorNotification(addNotifications);
    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getFileUrl = async (media: Media): Promise<string> => {
  return await media.getContentTemporaryUrl().then();
};

export const getMessages = async (
  conversation: Conversation
): Promise<Paginator<Message>> =>
  await conversation.getMessages(CONVERSATION_PAGE_SIZE);
