import { Client } from "@twilio/conversations";
import { Box } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";

import Settings from "../settings/Settings";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import { useEffect, useState } from "react";

interface ConversationDetailsProps {
  convoSid: string;
  participants: ReduxParticipant[];
  convo: ReduxConversation;
  client: Client;
}

const ConversationDetails: React.FC<ConversationDetailsProps> = (
  props: ConversationDetailsProps
) => {
  const theme = useTheme();
  const attributes = props.convo.attributes;
  return (
    <Box
      style={{
        minHeight: 65,
        maxHeight: 65,
        paddingLeft: 16,
        paddingRight: 16,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: theme.borderColors.colorBorderWeak,
      }}
    >
      <Box
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box
            color="colorText"
            fontFamily="fontFamilyText"
            fontSize="fontSize50"
            lineHeight="lineHeight80"
            fontWeight="fontWeightBold"
            maxHeight="100%"
          >
            {attributes && attributes.first_name && attributes.last_name
              ? `${attributes.first_name} ${attributes.last_name}`
              : props.convo.friendlyName ?? props.convo.sid}
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
            color="colorTextDecorative30"
            fontFamily="fontFamilyText"
            fontSize="fontSize20"
            lineHeight="lineHeight80"
            fontWeight="fontWeightBold"
            maxHeight="100%"
          >
            {`${attributes.messageTransport}`}
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Settings
            convo={props.convo}
            participants={props.participants}
            client={props.client}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationDetails;
