import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useComboboxPrimitive } from "@twilio-paste/combobox-primitive";
import { useUID } from "@twilio-paste/core/uid-library";
import { Box, Input } from "@twilio-paste/core";

import { Label } from "@twilio-paste/label";
import { HelpText } from "@twilio-paste/help-text";
import { Combobox } from "@twilio-paste/core/combobox";
import { InputType } from "../../types";
import styles from "../../styles";

import { searchAllUsers } from "../../api";

import "./ModalCombobox.css";

interface ModalComboboxProps {
  label: string;
  input: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  error?: string;
  placeholder?: string;
  help_text?: string;
  isFocused?: boolean;
  setShowPassword?: Dispatch<SetStateAction<boolean>>;
}

const ModalCombobox: React.FC<ModalComboboxProps> = (
  props: ModalComboboxProps
) => {
  const items: string[] = ["Loading"];
  const disabledItems: string[] = ["Loading"];
  const [inputItems, setInputItems] = useState(items);
  const [disabledInputItems, setDisabledInputItems] = useState(disabledItems);

  const [selectedItem, setSelectedItem] = useState();
  const [value, setValue] = useState("");

  // daily human access token
  const dht = localStorage.getItem("dht") ?? "";

  return (
    <>
      <Combobox
        autocomplete
        element="DHCombobox"
        autoFocus={props.isFocused ?? false}
        labelText={props.label}
        placeholder={props.placeholder}
        disabledItems={disabledInputItems}
        items={inputItems}
        hasError={!!props.error}
        helpText={props.error || props.help_text}
        onBlur={props.onBlur}
        onInputValueChange={async ({ inputValue }) => {
          setInputItems(["Loading"]);
          setDisabledInputItems(["Loading"]);
          let users = [];
          if (inputValue !== undefined) {
            const keyword = inputValue.trim();
            if (keyword.length != 0) {
              users = await searchAllUsers(dht, keyword);
            }
            setValue(inputValue);
          }
          if (users.length) {
            setInputItems(users);
            setDisabledInputItems([]);
          } else {
            setInputItems(["No users found"]);
            setDisabledInputItems(["No users found"]);
          }
        }}
        selectedItem={selectedItem}
        onSelectedItemChange={(changes) => {
          setSelectedItem(changes.selectedItem);
          if (changes.selectedItem && changes.selectedItem.contact_phone) {
            props.onChange(changes.selectedItem.contact_phone);
          }
        }}
        optionTemplate={(item) => (
          <>
            {item.id && (
              <>
                {/*
                <img
                  src={item.images["250x250"]}
                  width="24"
                  height="24"
                  style={{ margin: "0 .5em -.5em 0" }}
                />
                */}
                {item.first_name} {item.last_name} ({item.contact_phone})
              </>
            )}
            {!item.id && item}
          </>
        )}
        itemToString={(item) =>
          item ? item.first_name + " " + item.last_name : ""
        }
      />
    </>
  );
};

export default ModalCombobox;
