import { useState } from "react";
import { Button } from "@twilio-paste/button";
import { Box } from "@twilio-paste/core";
import { ProductConversationsIcon } from "@twilio-paste/icons/esm/ProductConversationsIcon";

import { getToken, getDht } from "../../api";
import { InputType } from "../../types";
import ModalInputField from "../modals/ModalInputField";
import styles from "../../styles";
import TwilioLogo from "../icons/TwilioLogo";

type SetTokenType = (token: string) => void;

interface LoginProps {
  setToken: SetTokenType;
}

async function login(
  username: string,
  password: string,
  setToken: (token: string) => void
): Promise<string> {
  try {
    username = username.trim();

    const dht = await getDht(username, password);
    if (dht === "") {
      return "[DHT] Something went wrong.";
    }

    const token = await getToken(dht);
    if (token === "") {
      return "Something went wrong.";
    }

    localStorage.setItem("displayName", username);
    localStorage.setItem("username", process.env.REACT_APP_DH_USERNAME ?? "");
    localStorage.setItem("dht", dht);
    setToken(token);

    return "";
  } catch (error) {
    return String(error);
  }
}

const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const [isFormDirty, setFormDirty] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <Box style={styles.loginContainer}>
      <Box style={styles.loginContent}>
        <Box>
          <img
            src="/dailyhuman.svg"
            alt="dailyhuman logo"
            style={styles.logo}
          />
        </Box>
        <Box style={styles.loginForm}>
          <form
            onSubmit={(event: React.SyntheticEvent) => {
              async () => {
                const error = await login(username, password, props.setToken);
                if (error) {
                  setFormError(error);
                }
              };
              event.preventDefault();
            }}
          >
            <Box style={styles.userInput}>
              <ModalInputField
                label="Email"
                placeholder=""
                isFocused={true}
                error={
                  isFormDirty && !username.trim()
                    ? "Enter an email to sign in."
                    : ""
                }
                input={username}
                inputType={InputType.Email}
                onBlur={() => setFormDirty(true)}
                onChange={setUsername}
              />
            </Box>
            <Box style={styles.passwordInput}>
              <ModalInputField
                label="Password"
                placeholder=""
                error={
                  isFormDirty && !password
                    ? "Enter a password to sign in."
                    : formError ?? ""
                }
                input={password}
                onChange={setPassword}
                inputType={showPassword ? InputType.Text : InputType.Password}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
              />
            </Box>
            <Box style={styles.loginButton}>
              <Button
                fullWidth
                disabled={!username || !password}
                variant="primary"
                type="submit"
                onClick={async () => {
                  const error = await login(username, password, props.setToken);
                  if (error) {
                    setFormError(error);
                  }
                }}
              >
                Sign in
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
      <Box style={styles.loginBackground}>
        {/* move to styles file */}
        <Box
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#fcf9f3",
            transform: "skewY(-12deg)",
            transformOrigin: "top right",
          }}
        />
      </Box>
    </Box>
  );
};

export default Login;
