import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";
import { Avatar } from "@twilio-paste/avatar";
import { Menu, MenuButton, useMenuState, MenuItem } from "@twilio-paste/menu";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import React from "react";
import styles from "../styles";
import useMediaQuery from "../hooks/useMediaQuery";

type AppHeaderProps = {
  user: string;
  onSignOut: () => void;
};
const AppHeader: React.FC<AppHeaderProps> = ({ user, onSignOut }) => {
  const isDesktop = useMediaQuery("(min-width: 500px)");
  const menu = useMenuState();
  const displayName = localStorage.getItem("displayName") ?? "";
  return (
    <div style={styles.appHeader}>
      <div style={styles.appLogo}>
        <img
          src="/dailyhuman.svg"
          alt="dailyhuman logo"
          style={styles.logoApp(isDesktop)}
        />
      </div>
      <div style={styles.userTile}>
        <Avatar size="sizeIcon70" name="avatar example" icon={UserIcon} />
        <div style={styles.userName}>{displayName}</div>
        <MenuButton {...menu} variant="link" size="reset">
          <ChevronDownIcon
            color="colorTextInverse"
            decorative={false}
            title="Settings"
          />
        </MenuButton>
        <Menu {...menu} aria-label="Preferences">
          <MenuItem {...menu} onClick={onSignOut}>
            Sign Out
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default AppHeader;
