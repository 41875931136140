import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ModalFooter, ModalFooterActions } from "@twilio-paste/modal";
import { useTheme } from "@twilio-paste/theme";
import { ModalBody, Box, Text, Truncate } from "@twilio-paste/core";
import { ProductAssetsIcon } from "@twilio-paste/icons/cjs/ProductAssetsIcon";
import { Button } from "@twilio-paste/button";

import { ActionName } from "../../types";
import ConvoModal from "../modals/ConvoModal";
import {
  TemplateMessage,
  TemplateMessageEntity,
  TemplateMessageMedia,
} from "./TemplateMessage";

import { getTemplates } from "../../api";

interface ConversationTitleModalProps {
  title: string;
  isModalOpen: boolean;
  onCancel: () => void;
  onSave: (messages: any[]) => Promise<void>;
  type: string;
}

const TemplateModal: React.FC<ConversationTitleModalProps> = (
  props: ConversationTitleModalProps
) => {
  const [title, setTitle] = useState(props.title);
  const [error, setError] = useState("");
  const [isFormDirty, setFormDirty] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (props.title !== title) {
      setTitle(props.title);
    }
  }, [props.title]);

  const onCancel = () => {
    setError("");
    setTitle(props.title);
    props.onCancel();
  };

  const isBadTitle =
    title.length < 5 || title.trim() === props.title || title.trim()[0] != "+";

  const onSave = async () => {
    setError("");

    try {
      await props.onSave(messages);
    } catch (e) {
      setError((e as Error).message ?? "");
    }

    setLoading(false);
  };

  const onSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();

    if (isBadTitle || isLoading) {
      return;
    }

    setLoading(true);
    onSave();
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (isLoading) {
      return;
    }

    if (e.key === "Escape") {
      e.preventDefault();
      onCancel();
    }
  };

  const [messages, setMessages] = useState<TemplateMessageEntity[]>([]);
  useEffect(() => {
    async function getMessageTemplates() {
      if (props.isModalOpen) {
        const dht = localStorage.getItem("dht") ?? "";
        const mt = await getTemplates(dht, props.type);
        setMessages(mt[props.type]);
      }
    }

    getMessageTemplates();
  }, [props.isModalOpen, props.type]);

  const theme = useTheme();

  return (
    <>
      <ConvoModal
        title="Onboarding"
        isModalOpen={props.isModalOpen}
        handleClose={onCancel}
        modalBody={
          <ModalBody>
            <Box as="form" onSubmit={onSubmit} onKeyDown={onKeyDown}>
              <div style={{ overflow: "scroll" }}>
                <>
                  {messages.map((message, index) => {
                    message.body = message.body.replace("<userName>", title);

                    return (
                      <div key={index + "templateModalMessage"}>
                        <TemplateMessage message={message} />
                      </div>
                    );
                  })}
                </>
              </div>
            </Box>
          </ModalBody>
        }
        modalFooter={
          <ModalFooter>
            <ModalFooterActions>
              <Button
                disabled={isLoading}
                variant="secondary"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={onSave}>
                Select
              </Button>
            </ModalFooterActions>
          </ModalFooter>
        }
      />
    </>
  );
};

export default TemplateModal;
