import React, { useState } from "react";
import { Client } from "@twilio/conversations";

import ConversationTitleModal from "../modals/ConversationTitleModal";
import {
  addConversation,
  getConversationByUniqueName,
  getUserByPhone,
} from "../../api";
import { Button } from "@twilio-paste/button";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";

import { SMS_PREFIX, WHATSAPP_PREFIX } from "../../constants";

interface NewConvoProps {
  client?: Client;
  collapsed: boolean;
}

const CreateConversationButton: React.FC<NewConvoProps> = (
  props: NewConvoProps
) => {
  const dispatch = useDispatch();
  const {
    updateCurrentConversation,
    updateConversation,
    addNotifications,
    updateParticipants,
  } = bindActionCreators(actionCreators, dispatch);

  const [isModalOpen, setIsModalOpen] = useState(
    location.pathname == "/conversation/new"
  );
  const handleOpen = () => setIsModalOpen(true);

  const getConversationTransport = (userTransport: string) => {
    return userTransport === "SMS" ? SMS_PREFIX : WHATSAPP_PREFIX;
  };

  return (
    <>
      <Button fullWidth variant="secondary" onClick={handleOpen}>
        <PlusIcon decorative={false} title="Add convo" />
        {!props.collapsed ? "Join Conversation" : null}
      </Button>
      <ConversationTitleModal
        title=""
        type="new"
        isModalOpen={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          history.pushState({}, "", "/");
        }}
        onSave={async (phone: string) => {
          // daily human access token
          const dht = localStorage.getItem("dht") ?? "";
          const dh_user = await getUserByPhone(dht, phone);

          let convo = null;
          if (props.client) {
            try {
              convo = await getConversationByUniqueName(
                dh_user.id,
                props.client
              );
            } catch {
              convo = null;
            }
          }

          if (!convo) {
            convo = await addConversation(
              dh_user.id,
              dh_user.id,
              dh_user,
              updateParticipants,
              props.client,
              addNotifications
            );

            if (convo) {
              dh_user.friendlyName =
                dh_user.first_name + " " + dh_user.last_name;
              await convo.addNonChatParticipant(
                getConversationTransport(dh_user.messageTransport) +
                  process.env.REACT_APP_DH_PROXY_PHONE,
                getConversationTransport(dh_user.messageTransport) + phone,
                dh_user
              );
            }
          } else {
            const participants = await convo.getParticipants();
            let noDHUser = true;
            for (let i = participants.length - 1; i >= 0; i--) {
              const bindings: any = participants[i].bindings;
              if (bindings && bindings.sms) {
                if (
                  bindings.sms.address !==
                  getConversationTransport(dh_user.messageTransport) + phone
                ) {
                  await participants[i].remove();
                } else {
                  noDHUser = false;
                }
              }
            }

            if (noDHUser) {
              dh_user.friendlyName =
                dh_user.first_name + " " + dh_user.last_name;
              await convo.addNonChatParticipant(
                getConversationTransport(dh_user.messageTransport) +
                  process.env.REACT_APP_DH_PROXY_PHONE,
                getConversationTransport(dh_user.messageTransport) + phone,
                dh_user
              );
            }

            const convoAttributes: any = convo.attributes;
            if (
              convoAttributes &&
              convoAttributes.first_name &&
              convoAttributes.last_name &&
              (convoAttributes.first_name !== dh_user.first_name ||
                convoAttributes.last_name !== dh_user.last_name)
            ) {
              convo.updateAttributes(dh_user);

              updateConversation(convo.sid, {
                ...convo,
                attributes: dh_user,
              });
            }
          }
          setIsModalOpen(false);
          history.pushState({}, "", "/");

          if (convo) {
            updateCurrentConversation(convo.sid);
          }
          convo.updateAttributes(dh_user);
          updateConversation(convo.sid, {
            ...convo,
            attributes: dh_user,
          });
        }}
      />
    </>
  );
};

export default CreateConversationButton;
