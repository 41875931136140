import { Message, Participant } from "@twilio/conversations";

export type AddMessagesType = (channelSid: string, messages: Message[]) => void;
export type SetSidType = (sid: string) => void;

export type SetParticipantsType = (
  participants: Participant[],
  sid: string
) => void;

export type SetUnreadMessagesType = (
  channelSid: string,
  unreadCount: number
) => void;

export enum ActionName {
  Join = "Join",
  Save = "Save",
  Create = "Create",
  Manage = "Manage",
}

export enum InputType {
  Email = "email",
  Text = "text",
  Password = "password",
}

export enum Content {
  AddChat = "Add chat participant",
  AddSMS = "Add SMS participant",
  AddWhatsApp = "Add WhatsApp participant",
}

export type AssignedGroup = {
  createdAt: string;
  id: string;
  name: string;
  type: string;
  updatedAt: string;
};

export type UserInfo = {
  id: string;
  sendReminders: boolean;
  assignedGroups: AssignedGroup[];
  firstName: string;
  lastName: string;
  fullName: string;
  dob: string | null;
  gender: string | null;
  email: string;
  status: string;
  type: "SYS_USER" | "CUSTOMER" | "GAME_REVIEWER";
  phone: string | null;
  createdAt: string;
  updatedAt: string;
  twilioSettings?: {
    enabled: boolean;
    mmsSupported: boolean;
    whatsappSupported?: boolean;
  };
  messageTransport?: string;
  timezone?: string;
  groups?: string[];
  note?: string;
  vCardSavedDate?: string;
};
